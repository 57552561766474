import { createContext, useMemo } from 'react';

// types
import type { ActiveHotspot, Hotspot } from './product-slider.types';

type ProductSliderContextType = {
  slidePercentage: number;
  fixedOverlayOpen: boolean;
  hotspotOpen: boolean;
  setHotspotOpen: (value: boolean) => void;
  sliderContainerWidth: number;
  slideHandlerRef: React.MutableRefObject<HTMLDivElement | null>;
  onButtonClick: (e: React.MouseEvent<SVGElement | HTMLButtonElement>, direction?: string) => void;
  onButtonDrag: (event?: React.SyntheticEvent<HTMLButtonElement | HTMLSpanElement, Event>) => void;
  hotspotOverlayRef: React.MutableRefObject<HTMLDivElement | null>;
  setLastHotspot: (lastHotspot?: ActiveHotspot) => void;
  tooltip?: Hotspot;
  setTooltip: (tooltip?: Hotspot) => void;
};

export const ProductSliderContext = createContext({} as ProductSliderContextType);

export function ProductSliderContextProvider({
  children,
  slidePercentage,
  fixedOverlayOpen,
  hotspotOpen,
  setHotspotOpen,
  sliderContainerWidth,
  slideHandlerRef,
  onButtonClick,
  onButtonDrag,
  hotspotOverlayRef,
  setLastHotspot,
  tooltip,
  setTooltip,
}: React.PropsWithChildren<ProductSliderContextType>) {
  const value = useMemo(
    () => ({
      slidePercentage,
      fixedOverlayOpen,
      hotspotOpen,
      setHotspotOpen,
      sliderContainerWidth,
      slideHandlerRef,
      onButtonClick,
      onButtonDrag,
      hotspotOverlayRef,
      setLastHotspot,
      tooltip,
      setTooltip,
    }),
    [
      slidePercentage,
      fixedOverlayOpen,
      hotspotOpen,
      setHotspotOpen,
      sliderContainerWidth,
      slideHandlerRef,
      onButtonClick,
      onButtonDrag,
      hotspotOverlayRef,
      setLastHotspot,
      tooltip,
      setTooltip,
    ],
  );

  return <ProductSliderContext.Provider value={value}>{children}</ProductSliderContext.Provider>;
}
