import React from 'react';
import { Row, Col } from '@geberit/gdds';

// styles
import styles from './interactive-hotspot.module.scss';

// types
import type { ContentWrapperProps } from './interactive-hotspot.types';

// components
import Content from './content';
import HotspotImage from '../hotspots/hotspot-image';
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';

function ContentWrapper({
  imageMap: { areas, image, imageAlt, imageObject },
  imageRef,
  imageAlignment,
  colorScheme,
  title,
  subtitle,
  emphasize,
  description,
  link,
  lastHotspot,
  setLastHotspot,
  hotspotOpen,
  setHotspotOpen,
  textRef,
  children,
}: Readonly<ContentWrapperProps>) {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const columnSizeText = () => {
    if (imageAlignment === 'center') {
      return [4, 8, 8];
    }
    if (isPortrait) {
      return [4, 8, 5];
    }
    return [4, 8, 6];
  };

  const columnSizeImage = imageAlignment === 'center' ? [4, 8, 12] : [4, 8, 6];

  const imageLeft = imageAlignment === 'left';
  const imageRight = imageAlignment === 'right';
  const isPortrait = imageObject.aspectRatio === '3_4';

  const renderContent = () => (
    <Content
      title={title}
      subtitle={subtitle}
      link={link}
      emphasize={emphasize}
      isMobile={isMobile}
      textRef={textRef}
      description={description}
      textLeft={imageRight}
    />
  );

  const renderImage = () => (
    <HotspotImage
      image={image}
      imageAlt={imageAlt}
      imageObject={imageObject}
      imageRef={imageRef}
      aspectRatio={!isMobile ? imageObject.aspectRatio : ''}
      shouldHaveImgWrapper={isMobile}
      areas={areas}
      colorScheme={colorScheme}
      lastHotspot={lastHotspot}
      setLastHotspot={setLastHotspot}
      hotspotOpen={hotspotOpen}
      setHotspotOpen={setHotspotOpen}
    />
  );
  return (
    <div className={styles.relativeContainer}>
      <Row>
        {/* image is left */}
        {isDesktop && imageLeft && <Col size={columnSizeImage}>{renderImage()}</Col>}

        {/* empty Col when portrait image is left */}
        {isDesktop && isPortrait && imageLeft && <Col size={[0, 0, 1]}></Col>}
        {/* text Col */}
        <Col size={columnSizeText()}>{renderContent()}</Col>
        {/* empty Col when portrait image is right */}
        {isDesktop && isPortrait && imageRight && <Col size={[0, 0, 1]}></Col>}

        {/* image is left on mobile */}
        {!isDesktop && imageLeft && <Col size={columnSizeImage}>{renderImage()}</Col>}
        {/* image is centered */}
        {!imageRight && !imageLeft && <Col size={columnSizeImage}>{renderImage()}</Col>}
        {/* image is right */}
        {imageRight && <Col size={columnSizeImage}>{renderImage()}</Col>}
      </Row>
      {children}
    </div>
  );
}

export default ContentWrapper;
