// utils
import { trackTemplate } from 'utils/tracking';

export function clickHotspot(title) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Clicks',
      action: 'HotSpot',
      label: title,
      value: '0',
    },
  });
}
