// styles
import styles from './hotspot.module.scss';

// types
import type { HotspotImageProps } from './hotspot.types';

// components
import { Image } from 'components/image/image';
import Hotspots from './hotspots';

export default function HotspotImage({
  imageRef,
  activeHotspot = undefined,
  isWorldMap = false,
  shouldHaveImgWrapper = false,
  lastHotspot = undefined,
  hotspotOpen = false,
  setHotspotOpen = undefined,
  setActiveHotspot = undefined,
  areas,
  image,
  imageAlt,
  imageObject,
  colorScheme,
  setLastHotspot,
  aspectRatio,
}: Readonly<HotspotImageProps>) {
  const imageFormat = imageObject?.aspectRatio === '2_1' ? 'wide' : 'portrait';

  return (
    <div className={`${styles.imageWrapper} ${styles[imageFormat]}`} ref={imageRef}>
      <Image
        src={image}
        alt={imageAlt}
        title={imageAlt}
        data={imageObject}
        aspectRatio={aspectRatio}
        shouldHaveImgWrapper={shouldHaveImgWrapper}
        format={undefined}
        staticImage={undefined}
        external={undefined}
        lazy={undefined}
      >
        <Hotspots
          areas={areas}
          colorScheme={colorScheme}
          activeHotspot={activeHotspot}
          setActiveHotspot={setActiveHotspot}
          isWorldMap={isWorldMap}
          lastHotspot={lastHotspot}
          setLastHotspot={setLastHotspot}
          hotspotOpen={hotspotOpen}
          setHotspotOpen={setHotspotOpen}
        />
      </Image>
    </div>
  );
}
